<template>
  <v-container >
    <v-card>
     

      <v-data-table v-if="sellers" :headers="headers" :items="sellers" class="elevation-1">
        <template v-slot:top>
          
          <v-toolbar  flat class="teal" dark>
            <v-toolbar-title>Vendedores </v-toolbar-title>
                        <v-spacer></v-spacer>

              <v-toolbar-items>
            <v-select
            v-if="periods"
            class="mt-4 ml-5"
            placeholder="Periodo"
      :items="periods"
            item-text="periodo"
            item-value="id"
            v-model="periodSelect"
            @change="getAllSeller()"
    ></v-select>
              </v-toolbar-items>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:item.time="{ item }">
          <!--eslint-disable-line -->

          <v-chip label color="primary" outlined>
            {{ item.time }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <!-- eslint-disable-line-->

          <!--eslint-disable-line -->

          <!-- eslint-disable-line-->

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="sellerSelected(item)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Visualizar Vendas</span>
          </v-tooltip>
        </template>
      </v-data-table>
      
    </v-card>
    <v-divider class="mt-10"></v-divider>

    <v-dialog
      v-model="dialog"
      v-if="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Acompanhamento de Vendas</v-toolbar-title>
          
          <v-toolbar-items>
            <v-select
            class="mt-3 ml-5"
              :items="sellers"
              placeholder="Selecionar vendedor"
              item-text="nome"
              return-object
              v-model="sellerSelect"
              @change="selectSales"
            ></v-select>
          </v-toolbar-items>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>

          <DashSalePerson :seller="seller" :key="seller" v-if="seller"/>
        </v-card-text>

      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import DashSalePerson from "../DashSalePerson";
export default {
  components: {
    DashSalePerson,
  },
  data: () => {
      return {
    valid: true,
    dialog: false,
    disabled: true,
    teams: null,
    seller: "",
    periodSelect: 2,
    periods: null,
    teamSelected: null,
    name: "",
    sellerSelect: null,
    email: "",
    select: null,
    items: ["Externo", "Interno", "Loja"],
    headers: [
      { text: "Vendedor", value: "nome" },
      { text: "Time", value: "time" },
      {text: "Previsto", value: "Previsto"},
      {text: "Ganho", value: "Ganho"},
      
      {text: "Debito", value: "Debito"},
{text: "Pago", value: "Pago"},
      { text: "Ações", value: "actions" },
    ],

    checkbox: false,
    show2: false,
    sellers: [],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
    },
  }
  },
  async created() {
    await this.getPeriods();
        this.getAllSeller();

  },
  
  methods: {
      selectSales(){
          this.seller = this.sellerSelect.id
  },
    sellerSelected(args) {
      this.dialog = true;
      this.seller = args.id;
      this.teamSelected = args;
    },
    getColor(status) {
      if (!status) return "success";
      else return "error";
    },
    async getAllSeller() {
      const {data} = await this.$http.get(`saleperson/${this.periodSelect}/period`);

      data.forEach(e => {
        e.Ganho = "R$ " + e.Ganho.toFixed(2)
        e.Previsto = "R$ " + e.Previsto.toFixed(2)
        e.Debito = "R$ " + e.Debito.toFixed(2)
        e.Pago = "R$ " + e.Pago.toFixed(2)

      });
      this.sellers = data;

    },
     async getPeriods() {
      try {
        const result = await this.$http.get("/saleperiod");
        const inprogress = await this.$http.get("/saleperiod/inprogress")
        this.periods = result.data;
        this.periodSelect = inprogress.data.id;

      } catch (error) {
        this.$toast.error("Erro ao consultar periodos!")
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        // axios.post("/api/submit", {
        //     name: this.name,
        //     email: this.email,
        //     select: this.select,
        //     checkbox: this.checkbox
        // });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
</style>