var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[(_vm.sellers)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.sellers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"teal",attrs:{"flat":"","dark":""}},[_c('v-toolbar-title',[_vm._v("Vendedores ")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.periods)?_c('v-select',{staticClass:"mt-4 ml-5",attrs:{"placeholder":"Periodo","items":_vm.periods,"item-text":"periodo","item-value":"id"},on:{"change":function($event){return _vm.getAllSeller()}},model:{value:(_vm.periodSelect),callback:function ($$v) {_vm.periodSelect=$$v},expression:"periodSelect"}}):_vm._e()],1),_c('v-spacer')],1),_c('v-divider')]},proxy:true},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(item.time)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.sellerSelected(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Vendas")])])]}}],null,false,3893207308)}):_vm._e()],1),_c('v-divider',{staticClass:"mt-10"}),(_vm.dialog)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Acompanhamento de Vendas")]),_c('v-toolbar-items',[_c('v-select',{staticClass:"mt-3 ml-5",attrs:{"items":_vm.sellers,"placeholder":"Selecionar vendedor","item-text":"nome","return-object":""},on:{"change":_vm.selectSales},model:{value:(_vm.sellerSelect),callback:function ($$v) {_vm.sellerSelect=$$v},expression:"sellerSelect"}})],1),_c('v-spacer')],1),_c('v-card-text',[(_vm.seller)?_c('DashSalePerson',{key:_vm.seller,attrs:{"seller":_vm.seller}}):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }